import BasePlugin from '../BasePlugin'

export default class MemberRegistrationQuery extends BasePlugin {
  async execute () {
    let publicDiscussionId = this.context.getModel().id
    let me = this
    this.context.$http.get(`${this.context.$config.api}/registryservice/plugins/execute/MemberRegistrationQuery?publicDiscussionId=${publicDiscussionId}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then((response) => {
      switch (response.data.message) {
        case 'new_member':
          this.context.$nextTick(() => {
            me.context.openRegistryCard({
              registryId: 34,
              cardId: 30,
              cardName: 'Участник',
              recordId: null,
              initialData: { 'attr_48_': me.context.getModel().id }
            })
          })
          break
        case 'registration_in_process':
          this.context.$msgbox({
            type: 'info',
            message: 'Вами уже подана заявка на участие в данном общественном обсуждении. Пожалуйста, ожидайте решение модератора'
          })
          break
        case 'registered':
          this.context.$msgbox({
            type: 'info',
            message: 'Вы уже зарегистрированы на данное общественное обсуждение'
          })
          break
        case 'registration_denied':
          let message = `Вам отказано в регистрации на участие в обсуждении по причине - "${response.data.rejectionReason}". Вы хотите повторно отправить заявку на регистрацию в общественном обсуждении?`
          this.context.$msgbox({
            title: 'Регистрация участника',
            message: message,
            showCancelButton: true,
            confirmButtonText: 'Да',
            beforeClose: (action, instance, done) => {
              if (action === 'confirm') {
                this.context.$nextTick(() => {
                  me.context.openRegistryCard({
                    registryId: 34,
                    cardId: 30,
                    cardName: 'Участник',
                    recordId: null,
                    initialData: { 'attr_48_': me.context.getModel().id }
                  })
                })
                done()
              } else {
                this.context.$msgbox.close()
              }
            }
          })
          break
      }
      done()
    })
  }
}
